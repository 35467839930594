var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-tname":"WaveItem"}},[_c('div',{staticClass:"main-container"},[_c('div',{staticClass:"waves"},_vm._l((_vm.waves),function(item,key){return _c('div',{key:key,staticClass:"wave",style:(item)},_vm._l((_vm.wavesConfig.total),function(n){return _c('div',{key:n,staticClass:"wave-item",style:({
              transform: ("scale(" + (0.1 * Math.sqrt(n - 1)) + ")"), // 使得波纹大小指数增长
              opacity: 0.5 * (1 / n), // 因为相互层叠的波纹透明度会相互叠加，需要越小的波纹透明度越低，以免中心颜色过重
              animationDelay: (((n - 1) * 0.12) + "s"), // 越大的波纹越晚出现，以呈现波纹逐渐扩散的效果
              animationDuration: ((0.6 +
                n * 0.3 +
                parseInt(item.width) * 0.002) + "s"), // 波纹动画时间渐增，表现波纹向外扩散渐慢的效果,波纹尺寸越大动画时间越长。
              backgroundColor: _vm.wavesConfig.waveColor
            })})}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
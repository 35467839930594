import Vue from 'vue'
import {
	Switch,
	Tree,
	Option,
	Select,
	Tag,
	Col,
	DropdownMenu,
	DropdownItem,
	Dropdown,
	Loading,
	Badge,
	Pagination,
	BreadcrumbItem,
	Breadcrumb,
	MessageBox,
	Form,
	FormItem,
	Dialog,
	Upload,
	Input,
	TableColumn,
	Table,
	Card,
	Submenu,
	Menu,
	MenuItem,
	MenuItemGroup,
	Header,
	Main,
	Aside,
	Button,
	Container,
	DatePicker,
	TimePicker,
	Divider,
	Tooltip,
	Row,
	Image,
	Tabs,
	TabPane,
	Drawer,
	Checkbox,
	CheckboxGroup,
	Notification,
	Link,
	Carousel,
	CarouselItem,
	Scrollbar,
	Progress,
	Avatar,
	Popconfirm,
	Radio,
	RadioGroup,
	Empty,
	InputNumber
} from 'element-ui'

Vue.use(Button)
	.use(Progress)
	.use(Container)
	.use(Aside)
	.use(Main)
	.use(Header)
	.use(MenuItemGroup)
	.use(MenuItem)
	.use(Menu)
	.use(Submenu)
	.use(Card)
	.use(TableColumn)
	.use(Table)
	.use(Input)
	.use(Dialog)
	.use(FormItem)
	.use(Form)
	.use(Upload)
	.use(Breadcrumb)
	.use(BreadcrumbItem)
	.use(Pagination)
	.use(Badge)
	.use(DropdownItem)
	.use(Dropdown)
	.use(DropdownMenu)
	.use(Col)
	.use(Tag)
	.use(Select)
	.use(Option)
	.use(Tree)
	.use(Loading.directive)
	.use(Switch)
	.use(DatePicker)
	.use(TimePicker)
	.use(Divider)
	.use(Tooltip)
	.use(Row)
	.use(Image)
	.use(Tabs)
	.use(TabPane)
	.use(Drawer)
	.use(Checkbox)
	.use(CheckboxGroup)
	.use(Link)
	.use(Carousel)
	.use(CarouselItem)
	.use(Scrollbar)
	.use(Avatar)
	.use(Popconfirm)
	.use(Radio)
	.use(RadioGroup)
	.use(Empty)
	.use(InputNumber)
	
Vue.prototype.$loading = Loading.service
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification

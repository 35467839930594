import request from '@/network/request.js'
/**
 * 验证修改密码
 */
export function submitForm(data) {
  return request({
    url: '/offerSystem/home/user/userMsg/changeUserPassWord',
    method: 'post',
    data,
  })
}

export function closePageTime({ logOutTime, userId }) {
  return request({
    url: '/offerSystem/logOut',
    method: 'get',
    params: {
      userId,
      logOutTime,
    },
  })
}

/* 设置通知 */
export const setNoticeHttp = ({ userId, msg }) => {
  return request({
    url: '/offerSystem/home/systemMsg/notice/sendNotification',
    method: 'post',
    data: {
      userId,
      msg,
    },
  })
}

/* 获取全部用户信息数据 */
export const getUserInfoHttp = (userId) => {
  return request({
    url: `/offerSystem/home/userPower/userMsg/findListNoPage?userId=${userId}`,
    method: 'get',
  })
}

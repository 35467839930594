import Vue from 'vue'
import Vuex from 'vuex'
const {
	get_locl_ip, //获取自己的后端ip的信息
} = require("@/api/materialad");
Vue.use(Vuex)
let state = {
	task_bar: [{
		path: '/home',
		name: '首页'
	}],
	userInfo: {},
	powrRouters: [],
	isAdmin: false,
	iphost: null
}
let mutations = {
	setTaskBarList(state, action) {
		if (action.meta.name) {
			for (let i = 0; i < state.task_bar.length; i++) {
				const element = state.task_bar[i]
				if (element.path === action.path || action.path === '/login') {
					return state.task_bar
				}
			}
		}
		if (action.meta.name == 'Excel编辑') {
			return
		}
		return state.task_bar.push({
			path: action.path,
			name: action.meta.name
		})
	},
	removeTaskBarList(state, action) {
		state.task_bar = action
	},
	setUserInfo(state, userInfo) {
		state.userInfo = userInfo
	},
	updatePowrRoutes(state, routers) {
		state.powrRouters = routers
	},
	get_locl_ipaction(state, host_name) {
		state.iphost = host_name
	}
}
let actions = {

	// 获取自己的ip信息供上传
	async get_locl_ipfun(store, action) {
		let get_locl_ipres = await get_locl_ip();
		store.commit('get_locl_ipaction', get_locl_ipres.data.host_name)
	},

	setTaskBarList(store, action) {
		store.commit('setTaskBarList', action)
	},
	removeTaskBarList(store, action) {
		store.commit('removeTaskBarList', action)
	},
	setUserInfo(store, userInfo) {
		store.commit('setUserInfo', userInfo)
	},
}
let modules = {}
let getters = {
	//   isAdmin(state) {
	//   return state.userInfo.user.roleId === 1 || state.userInfo.user.roleId === 2
	// },
	// isAdmin: (state) =>
	//   state.userInfo.user.roleId === 1 || state.userInfo.user.roleId === 2,
	// toKen: (state) => state.userInfo.token,
}
export default new Vuex.Store({
	state,
	mutations,
	actions,
	modules,
	getters,
})

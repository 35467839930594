import axios from 'axios';
import { message } from '@/util/message';
import router from '../router/index'

import NProgress from 'nprogress'  //这是一个浏览器的上面这种加载动画
import 'nprogress/nprogress.css'
function throttle(fn) {
    let valid = true
    return function (args, delay) {
        if (!valid)
            return
        valid = false
        setTimeout(() => {
            console.log("timeout")
            fn(args)
            valid = true
        }, delay)
    }
}
const popMessage = throttle((message) => message.error(message));
const request = axios.create({
    timeout: 500000
})
request.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let token = window.localStorage.getItem("token")
    config.headers.token = token;
    NProgress.start();
    return config;
}, function (error) {
    // 对请求错误做些什么
    NProgress.done()
    return Promise.reject(error);
});
request.interceptors.response.use(
    // 对响应数据做点什么
    response => {
        console.log(1231, response)
        // response.data.code == 1 ? message({type: 'success',message: response.data.msg}) : message({type: 'error',message: response.data.msg});
        response.data.code == 0 ? message({ type: 'error', message: response.data.msg }) : "";
        NProgress.done()
        return Promise.resolve(response.data)
    }, (error) => {
        const {
            response: {
                status,
                data = {}
            } = {}
        } = error
        // 对响应错误做点什么

        // 需要登陆一下
        if (status == 401) {
            message({ type: 'error', message: data.msg })
            window.location.href = '/login'
            localStorage.removeItem("token");
        } else {
            message({ type: 'error', message: '网络错误,请稍后再试~' })
        }
        return Promise.reject(error);
    });
export default request;
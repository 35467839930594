import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './network/request';
import {
	is_button_power
} from './util/universal'
import './plugins/element.js'
import {
	message
} from './util/message'
import 'default-passive-events'
import 'element-ui/lib/theme-chalk/index.css'
import 'normalize.css'
import moment from 'moment' //时间的日期库文件
import "./util/btn_permission"

// 按钮点击效果组件
import waves from './commen/waves.vue'
Vue.component('waves', waves)

window.addEventListener('beforeunload', () => {
	localStorage.removeItem('auton')
})

import './assets/style/style.css'

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$request = request
Vue.prototype.$moment = moment
Vue.prototype.$is_button_power = is_button_power

// import *as echarts from 'echarts'
// Vue.prototype.$echarts = echarts

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

<template>
	<div id="app">
		<waves></waves>
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'app',
	}
</script>

<style lang="less">
	body,
	html,
	#app {
		height: 100%;
	}

	div::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		/**/
	}

	div::-webkit-scrollbar-track {
		background: rgb(239, 239, 239);
		border-radius: 2px;
	}

	div::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 10px;
	}

	div::-webkit-scrollbar-thumb:hover {
		background: #333;
	}

	div::-webkit-scrollbar-corner {
		background: #179a16;
	}
</style>

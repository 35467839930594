import Vue from "vue";
import store from "../store/index";
//按钮级权限
function Role(followId) {
  console.log(followId == id);
  console.log(id);
  let id = store.state.userInfo.userId;
  if (followId == id) { return true }
  return false
}
Vue.directive('btn', {
  inserted: function (el, binding, vnode) {
    // console.log(el);
    // if (!Role(binding.value.followUpPersonUserId)) {
    //   console.log("jinlai ");
    //   el.style.display = "none"
    // }
    // console.log(binding.value.followUpPersonUserId);
  }
})
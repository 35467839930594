<template>
	<div class="home">
		<el-container>
			<!-- 左侧菜单 -->
			<el-aside :width="isCollapse ? 'auto' : '200px'">
				<el-menu collapse-transition :default-active="path" router background-color="#001529"
					text-color="#bfcbd9" active-text-color="#409EFF" class="el-menu-vertical-demo"
					:collapse="isCollapse">
					<el-menu-item style="
              border-bottom: 1px solid #9e9e9e4d;
              height: 65px;
              padding-left: 14px;
            ">
						<img src="@/assets/logo.png" alt style="width: 45px" />
						<span style="
                margin-left: 15px;
                font-size: 17px;
                font-weight: 600;
                letter-spacing: 1px;
              ">管理系统</span>
					</el-menu-item>
					<!-- 没有子菜单 -->
					<template v-for="(item, index) in routerMenu">
						<template v-if="item.children.length < 2">
							<el-menu-item :key="index" :index="item.children ? item.children[0].path : item.path">
								<i :class="item.meta.icon"></i>
								<span slot="title">{{ item.meta.name }}</span>
							</el-menu-item>
						</template>
						<!-- 有子菜单 -->
						<template v-else>
							<el-submenu :key="item.path" :index="item.name + item.path">
								<template slot="title">
									<i :class="item.meta.icon"></i>
									<span>{{ item.meta.name }}</span>
								</template>
								<span v-for="items in item.children" :key="items.path" v-if="!items.hidden">
									<el-menu-item-group>
										<el-menu-item :index="items.path">
											<i :class="items.meta.icon"></i>
											{{ items.meta.name }}
										</el-menu-item>
									</el-menu-item-group>
								</span>
							</el-submenu>
						</template>

					</template>
				</el-menu>
			</el-aside>

			<!-- <button @click="clickRemindBtn">测试按钮</button> -->
			<!-- 右侧内容 -->
			<el-container class="mian">
				<!-- 头部 -->
				<header>
					<div class="top">
						<!-- 左侧菜单缩进按钮 -->
						<div class="left">
							<div style="margin-right: 20px">
								<div class="leftOpen" @click="isCollapseShow">
									<i class="el-icon-s-fold"></i>
								</div>
							</div>
							<!-- 面包屑导航 -->
							<el-breadcrumb separator="/">
								<el-breadcrumb-item v-for="item in levelList" :key="item.name"
									:to="{ path: item.path }">{{ item.meta.name }}</el-breadcrumb-item>
							</el-breadcrumb>
						</div>
						<!-- 用户头像 -->
						<div class="right">
							<div class="messageBox">
								<i class="el-icon-s-opportunity remind" style="font-size: 20px" @click="clickRemindBtn"
									v-if="this.$store.getters.isAdmin"></i>
								<!-- 							<router-link tag="span" to="/system/message/unread">
			<el-badge :value="newsList" class="item">
				<i class="el-icon-message-solid" style="font-size: 20px"></i>
			</el-badge>
		</router-link> -->
								<span style="margin-left: 10px; padding-left: 20px">
									{{user.user_name}}
								</span>
							</div>
							<el-dropdown ref="dropdown" trigger="click">
								<div style="display: flex; align-items: baseline">
									<div class="headerImg">
										<img :src="user.avatarUrl" style="height: 40px; width: 40px" />
									</div>
									<i class="el-icon-caret-bottom"></i>
								</div>
								<el-dropdown-menu slot="dropdown">
									<!--<el-dropdown-item>
										<router-link tag="span" to="/home/userinfo/info">个人信息</router-link>
									</el-dropdown-item>
									<el-dropdown-item>
										<div @click="editPassworld">修改密码</div>
									</el-dropdown-item> -->
									<el-dropdown-item divided>
										<div @click="exitLogin">退出登陆</div>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
					<!-- 下方面包屑 -->
					<div class="task_bar">
						<div class="item" :class="path == item.path ? 'itemActive' : ''"
							v-for="(item, index) in task_bar" :key="item.path" :title="item.name">
							<router-link tag="span" :to="item.path" class="router">
								<div>
									<div class="roudio"></div>
									{{ item.name }}
									<i @click="removeTaskBar(index)" v-if="item.path == path && item.path !== '/home'"
										class="el-icon-close"></i>
								</div>
							</router-link>
						</div>
					</div>
				</header>
				<!-- 路由跳转 -->
				<el-main>
					<transition name="fade-transform" mode="out-in">
						<router-view />
					</transition>
				</el-main>
			</el-container>
		</el-container>
		<el-dialog title="修改密码" :visible.sync="editPassworldVisible" width="50%">
			<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="旧密码" prop="oldPass">
					<el-input type="password" v-model="ruleForm.oldPass" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="pass">
					<el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkPass">
					<el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editPassworldVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		submitForm,
		closePageTime,
		setNoticeHttp,
		getUserInfoHttp
	} from "./api.js";
	// import navigation from './navigation.vue'
	import glbalBus from "@/util/globalBus.js";
	let beginTime = 0; //执行onbeforeunload的开始时间
	let differTime = 0; //时间差
	export default {
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请输入密码"));
				} else {
					if (this.ruleForm.checkPass !== "") {
						this.$refs.ruleForm.validateField("checkPass");
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === "") {
					callback(new Error("请再次输入密码"));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error("两次输入密码不一致!"));
				} else {
					callback();
				}
			};
			return {
				routerMenu: [],
				path: "", //获取前得路径
				isCollapse: false, //侧边栏显示隐藏
				timer: null, //定时器
				levelList: [], //获取面包屑层级
				editPassworldVisible: false,
				newsList: "",
				user: "",
				ruleForm: {
					pass: "",
					checkPass: "",
					oldPass: ""
				},
				rules: {
					// oldPass:[
					//    { required: true,message: '请输入原密码',  trigger: 'blur' },
					//    { min: 5, max: 17, message: '密码长度在 6 到 17 个字符', trigger: 'blur' }
					// ],
					pass: [{
							validator: validatePass,
							trigger: "blur"
						},
						{
							min: 6,
							max: 17,
							message: "密码长度在 6 到 17 个字符",
							trigger: "blur"
						}
					],
					checkPass: [{
							validator: validatePass2,
							trigger: "blur"
						},
						{
							min: 6,
							max: 17,
							message: "密码长度在 6 到 17 个字符",
							trigger: "blur"
						}
					]
				},
				/* 提醒表单数据 */
				remindRuleForm: {
					user: [],
					template: true,
					desc: "",
					templateText: "请尽快提交客户信息"
				},
				/* 提醒表单验证规则 */
				remindRules: {
					user: [{
						required: true,
						message: "请选择用户",
						trigger: "change"
					}]
				},
				allSelectText: "选择全部",
				/* 提醒 用户select的内容 */
				selectOptions: [],
				/* 提醒 用户select 点击全部后收集的数据 */
				selectedArray: []
			};
		},
		watch: {
			$route(to) {
				this.path = to.path;
				this.getBreadcrumb(to);
				clearTimeout(this.timer);
			}
		},
		computed: {
			...mapState(["task_bar", "userInfo", "powrRouters"])
		},
		methods: {
			hasOneShowingChild(children = []) {
				if (children.length === 1) {
					return true;
				}
				return false;
			},
			//验证修改密码
			submitForm(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						submitForm({
							userId: this.userInfo.userId,
							newPassWord: this.ruleForm.pass,
							passWord: this.ruleForm.oldPass,
							repeatNewPassWord: this.ruleForm.checkPass
						}).then(res => {
							console.log(res);
							this.editPassworldVisible = false;
							this.$refs[formName].resetFields();
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},

			//修改密码
			editPassworld() {
				this.editPassworldVisible = true;
			},

			//动态面包屑
			getBreadcrumb(val) {
				if (val.matched) {
					let matched = val.matched.filter(item => item.meta && item.meta.name);
					this.levelList = matched;
				}
			},

			//显示隐藏侧边栏
			isCollapseShow() {
				this.isCollapse = !this.isCollapse;
			},

			//退出登陆
			exitLogin() {
				/* 发送用户退出时间 */
				this.pageUnLoadHandle();

				localStorage.removeItem("token");
				localStorage.removeItem("auton");
				localStorage.removeItem("userInfo");
				// window.localStorage.removeItem("token");
				this.$message({
					type: "info",
					message: "退出登陆"
				});
				this.$router.push("/login");
				localStorage.clear();
			},

			//删除任务栏
			removeTaskBar(index) {
				this.task_bar.splice(index, 1);
				this.$store.dispatch("removeTaskBarList", this.task_bar);
				this.timer = setTimeout(() => {
					if (this.task_bar[index]) this.$router.push(this.task_bar[index].path);
					else this.$router.push(this.task_bar[index - 1].path);
				}, 100);
			},
			getAdd() {
				this.$request({
					url: `/offerSystem/home/systemMsg/notice/findNoticeByUserId?userId=${this.user.userId}`,
					method: "get"
				}).then(res => {
					//this.newsList = res.jsonObject.count;
				});
			},
			/* 页面关闭事件 发送用户登出时间的请求 */
			pageUnLoadHandle() {
				let data = new FormData();
				data.append(
					"logOutTime",
					this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
				);
				data.append("userId", this.user.userId);
				navigator.sendBeacon("/offerSystem/logOut", data);
			},
			/* 提醒弹窗关闭时 */
			remindDialogClose() {
				this.$refs.remindRuleFormRef.resetFields();
				this.allSelectText = "选择全部";
			},
			/* 点击选择全部按钮 */
			clickSelectAll() {
				if (this.selectedArray.length < this.selectOptions.length) {
					this.selectedArray = [];
					this.selectOptions.map(item => {
						this.selectedArray.push(item.value);
					});
				} else {
					this.selectedArray = [];
				}
				this.allSelectText =
					this.allSelectText === "选择全部" ? "取消全部" : "选择全部";
				this.remindRuleForm.user = this.selectedArray;
				console.log(this.remindRuleForm);
			},
			/* select 选中值改变时 */
			changeSelect() {
				this.allSelectText =
					this.remindRuleForm.user.length < this.selectOptions.length ?
					"选择全部" :
					"取消全部";
				this.selectedArray = this.remindRuleForm.user;
			},
			/* 获取全部用户信息数据 用于渲染提醒select选择器*/
			async getAllUserInfo() {
				const res = await getUserInfoHttp(this.user.userId);
				// console.log(res)
				// res.forEach(item => {
				//   let temp = {
				//     label: item.userName,
				//     value: item.userId
				//   };
				//   this.selectOptions.push(temp);
				// });
				// console.log(this.selectOptions)
			},
			/* 发送提醒 */
			async setNotice(params) {
				const res = await setNoticeHttp(params);
				console.log(res);
				if (res.result) {
					this.$message({
						type: "success",
						message: "提醒成功"
					});
				} else {
					this.$message.error("提醒失败");
				}
			}
		},
		created() {
			this.user = JSON.parse(window.localStorage.getItem("userInfo") || "{}");
			console.log("数据是:", this.user);
			this.getBreadcrumb(this.$route);
			this.path = this.$route.path;
			this.routerMenu = this.powrRouters;
			// this.getAdd();
			//下面这个filter 指代的是返回的是满足或者不满足里面的内容的这种情况  au ：徐
			this.routerMenu = this.powrRouters.filter(e => {

				return (
					e.path != "/excel" &&
					e.path != "/login" &&
					e.path != "*" &&
					e.path != "/home/userinfo" &&
					e.path != "/miniExcel" &&
					e.path != '/playinfo' &&
					e.path != '/member'
				);
			});

			this.$store.commit("setUserInfo", this.user);
			// console.log(this.user)
			localStorage.removeItem("filePath");
			localStorage.removeItem("fileUrl");
			localStorage.removeItem("fileName");
			glbalBus.$on("update-user", () => {
				this.getAdd();
			});
		},
		mounted() {
			// this.getAllUserInfo();
			window.addEventListener("beforeunload", () => {
				beginTime = new Date().getTime();
			});
			window.addEventListener("unload", () => {
				/* 
				  判断用户是刷新还是关闭
				  如果是刷新，differTime则大于5毫秒
				*/
				differTime = new Date().getTime() - beginTime;
				if (differTime < 5) {
					this.pageUnLoadHandle();
				}
			});
		}
	};
</script>

<style lang="less" scoped>
	header {
		box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
		position: relative;

		.top {
			display: flex;
			justify-content: space-between;
			padding: 0 20px;
			box-sizing: border-box;
		}

		.task_bar {
			display: flex;
			box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
			padding: 3px 10px;
			flex-wrap: nowrap;
			overflow-x: scroll;

			.item {
				margin-right: 5px;
				font-size: 14px;
				border: 1px solid #d8dce5;
				color: #495060;
				padding: 2px 8px;
				cursor: pointer;
				white-space: nowrap;
			}

			.itemActive {
				background: #42b983;
				border-color: #ffffff;

				.router {
					div {
						color: #fff;
						display: flex;
						align-items: center;

						.roudio {
							width: 8px;
							height: 8px;
							background: #fff;
							border-radius: 50%;
							margin-right: 5px;
						}

						.el-icon-close {
							margin-left: 5px;

							&:hover {
								margin-left: 5px;
								background: #fff;
								border-radius: 50%;
								color: #42b983;
							}
						}
					}
				}
			}
		}

		.left {
			color: #333;
			font-size: 24px;
			display: flex;
			align-items: center;

			.leftOpen {
				&:hover {
					cursor: pointer;
					background: rgba(0, 0, 0, 0.025);
				}

				width: 60px;
				height: 60px;
				text-align: center;
				line-height: 60px;
			}
		}

		.right {
			.messageBox {
				margin-right: 20px;
				cursor: pointer;
			}

			display: flex;
			align-items: center;

			.headerImg {
				padding: 10px;
				box-sizing: border-box;
				width: 60px;
				height: 60px;
				position: relative;

				&:hover {
					cursor: pointer;
					background: rgba(0, 0, 0, 0.025);
				}

				img {
					border-radius: 3px;
					width: 100%;
				}
			}
		}
	}

	.home_box {
		height: 100%;
	}

	.el-aside {
		height: 100vh;
		background: #001529;

		.el-menu {
			border-right: none;
		}
	}

	.mian {
		height: 100vh;
		flex-direction: column;
	}

	.el-main {
		height: 100%;
	}

	.el-main>div {
		height: 100%;
	}

	.fade-transform-leave-active,
	.fade-transform-enter-active {
		transition: all 0.5s;
	}

	.fade-transform-enter {
		opacity: 0;
		transform: translateX(-30px);
	}

	.fade-transform-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}

	.remind {
		margin-right: 20px;
		color: #ffb549;
	}
</style>
import layout from '../views/Home/Home.vue'
const routes = [
	//登录
	{
		path: '/login',
		name: 'Login',
		meta: {
			name: "登陆",
		},
		component: () => import('@/views/Login.vue'),
		hidden: true
	},
	//首页
	{
		path: '/',
		name: 'Home',
		redirect: "/home",
		component: layout,
		meta: {
			name: "首页",
			icon: 'el-icon-menu'
		},
		children: [{
			path: '/home',
			name: 'HomeIndex',
			meta: {
				name: "首页",
				icon: 'el-icon-menu'
			},
			component: () => import('@/views/Homepage/Index/index.vue')
		}]
	},
	//个人信息
	{
		path: "/home/userinfo",
		meta: {
			name: "个人信息",
			icon: 'el-icon-user-solid'
		},
		component: layout,
		children: [{
			path: "/home/userinfo/info",
			meta: {
				name: "个人信息",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/UserPage/Info/index.vue"),
			hidden: true
		}]
	},

	{
		path: "/interest",
		meta: {
			name: "趣玩测一测",
			icon: 'el-icon-user-solid'
		},
		component: layout,
		children: [{
			path: "/interest/classify",
			meta: {
				name: "话题类型",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/interest/classify.vue"),
		},
		{
			path: "/interest/gambit",
			meta: {
				name: "话题",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/interest/gambit.vue"),
		},
		{
			path: "/interest/judge",
			meta: {
				name: "评分标准",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/interest/judge.vue"),
		},
		{
			path: "/interest/topic",
			meta: {
				name: "题目管理",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/interest/topic.vue"),
		},

		]
	},


	// {
	// 	path: "/tik_tok_lk",
	// 	meta: {
	// 		name: "来客",
	// 		icon: 'el-icon-user-solid'
	// 	},
	// 	component: layout,
	// 	children: [
	// 		{
	// 			path: "/tik_tok_lk/store",
	// 			meta: {
	// 				name: "门店",
	// 				icon: 'el-icon-user-solid'
	// 			},
	// 			component: () => import("@/views/lk/store.vue"),
	// 		}, {
	// 			path: "/tik_tok_lk/commodity",
	// 			meta: {
	// 				name: "商品",
	// 				icon: 'el-icon-user-solid'
	// 			},
	// 			component: () => import("@/views/lk/commodity.vue"),
	// 		},
	// 		{
	// 			path: "/tik_tok_lk/order",
	// 			meta: {
	// 				name: "订单",
	// 				icon: 'el-icon-user-solid'
	// 			},
	// 			component: () => import("@/views/lk/order.vue"),
	// 		},

	// 		{
	// 			path: "/tik_tok_lk/check_ticket",
	// 			meta: {
	// 				name: "核销历史",
	// 				icon: 'el-icon-user-solid'
	// 			},
	// 			component: () => import("@/views/lk/check_ticket.vue"),
	// 		},


	// 	]
	// },

	{
		path: "/resource",
		meta: {
			name: "资源",
			icon: 'el-icon-user-solid'
		},
		component: layout,
		children: [{
			path: "/resource/resource_list",
			meta: {
				name: "资源信息",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/resource/resource_list.vue"),
		}]
	},

	// 芊寻主助手的
	{
		path: "/assistant",
		meta: {
			name: "芊寻主",
			icon: 'el-icon-user-solid'
		},
		component: layout,
		children: [{
			path: "/assistant/confessionweb",
			meta: {
				name: "表白",
				icon: 'el-icon-ship'
			},
			component: () => import("@/views/assistant/confessionweb.vue"),
		},
		{
			path: "/assistant/confessionweb_config/:confessid",
			meta: {
				name: "表白页面配置",
				icon: 'el-icon-user-solid'
			},
			component: () => import("@/views/assistant/confessionweb_config.vue"),
			hidden: true
		},
		{
			path: "/assistant/photo_list",
			meta: {
				name: "头像背景图",
				icon: 'el-icon-orange'
			},
			component: () => import("@/views/assistant/photo_list.vue"),
		},
		{
			path: "/assistant/short_url",
			meta: {
				name: "短链",
				icon: 'el-icon-paperclip'
			},
			component: () => import("@/views/assistant/short_url.vue"),
		},
		{
			path: "/assistant/make_last",
			meta: {
				name: "头像背景签名",
				icon: 'el-icon-lollipop'
			},
			component: () => import("@/views/assistant/make_last.vue"),
		},
		// {
		// 	path: "/assistant/resource",
		// 	meta: {
		// 		name: "用户资源",
		// 		icon: 'el-icon-user-solid'
		// 	},
		// 	component: () => import("@/views/assistant/short_url.vue"),
		// }
		]
	},


	// 用户模块
	// {
	// 	path: "/user",
	// 	meta: {
	// 		name: "用户模块",
	// 		icon: 'el-icon-user-solid'
	// 	},
	// 	component: layout,
	// 	children: [{
	// 		path: "/user/userlist",
	// 		meta: {
	// 			name: "表白",
	// 			icon: 'el-icon-user-solid'
	// 		},
	// 		component: () => import("@/views/assistant/confessionweb.vue"),
	// 	}]
	// },

	// {
	// 	path: '/AppletPage',
	// 	name: 'Applet',
	// 	component: layout,
	// 	meta: {
	// 		name: '小程序',
	// 		icon: 'el-icon-mobile'
	// 	},
	// 	children: [{
	// 		path: '/AppletPage',
	// 		name: 'AppletIndex',
	// 		meta: {
	// 			name: "小程序轮播图",
	// 			icon: 'el-icon-mobile'
	// 		},
	// 		component: () => import('@/views/Homepage/Applet/AppletPage.vue')
	// 	}, ]
	// },
	// 剧本审核
	// {
	// 	path: '/playaudit',
	// 	name: 'playaudit',
	// 	component: layout,
	// 	meta: {
	// 		name: '剧本审核',
	// 		icon: 'el-icon-mobile'
	// 	},
	// 	children: [{
	// 		path: '/playaudit',
	// 		name: 'playauditindex',
	// 		meta: {
	// 			name: "剧本审核",
	// 			icon: 'el-icon-mobile'
	// 		},
	// 		component: () => import('@/views/playaudit/index.vue')
	// 	}]
	// },

	// 据本视频
	// {
	// 	path: '/slidevideo',
	// 	name: 'slidevideo',
	// 	component: layout,
	// 	meta: {
	// 		name: '剧本视频',
	// 		icon: 'el-icon-video-camera-solid'
	// 	},
	// 	children: [{
	// 		path: '/slidevideo',
	// 		name: 'slidevideo',
	// 		meta: {
	// 			name: "剧本视频",
	// 			icon: 'el-icon-data-line'
	// 		},
	// 		component: () => import('@/views/slidevideo/index.vue')
	// 	}, {
	// 		path: "/slidevideo/childvideo/:mainid",
	// 		name: "childvideo",
	// 		meta: {
	// 			name: "子视频",
	// 			icon: 'el-icon-s-check'
	// 		},
	// 		component: () => import('@/views/slidevideo/childvideo.vue'),
	// 		hidden: true
	// 	}]
	// },

	// 据本视频
	// {
	// 	path: '/videoorder',
	// 	name: 'videoorder',
	// 	component: layout,
	// 	meta: {
	// 		name: '订单|记录',
	// 		icon: 'el-icon-s-shop'
	// 	},
	// 	children: [
	// 	{
	// 	path: '/videoorder',
	// 	name: 'videoorder',
	// 	meta: {
	// 		name: "剧本订单",
	// 		icon: 'el-icon-s-cooperation'
	// 	},
	// 	component: () => import('@/views/videoorder/index.vue')
	// },

	// 		{
	// 			path: '/videoorder/price',
	// 			name: 'price',
	// 			meta: {
	// 				name: "价格",
	// 				icon: 'el-icon-s-finance'
	// 			},
	// 			component: () => import('@/views/videoorder/price.vue')
	// 		}, {
	// 			path: '/videoorder/watchrecord',
	// 			name: 'watchrecord',
	// 			meta: {
	// 				name: "观看记录",
	// 				icon: 'el-icon-camera-solid'
	// 			},
	// 			component: () => import('@/views/videoorder/watchrecord.vue')
	// 		}, {
	// 			path: '/videoorder/recharge_record',
	// 			name: 'recharge_record',
	// 			meta: {
	// 				name: "充值记录",
	// 				icon: 'el-icon-s-release'
	// 			},
	// 			component: () => import('@/views/videoorder/recharge_record.vue')
	// 		}, {
	// 			path: '/videoorder/consumption_record',
	// 			name: 'consumption_record',
	// 			meta: {
	// 				name: "消费记录",
	// 				icon: 'el-icon-s-management'
	// 			},
	// 			component: () => import('@/views/videoorder/consumption_record.vue')
	// 		}
	// 	]
	// },

	//用户权限
 

	//系统消息
	// {
	// 	path: '/system',
	// 	name: 'system',
	// 	meta: {
	// 		name: '系统消息',
	// 		icon: 'el-icon-message-solid'
	// 	},
	// 	component: layout,
	// 	children: [
	// 		{
	// 			path: "/system/message/systemjournal",
	// 			name: "systemjournal",
	// 			meta: {
	// 				name: "系统日志",
	// 				icon: 'el-icon-notebook-2'
	// 			},
	// 			component: () => import('@/views/MessagePage/systemjournal/index.vue'),
	// 		},
	// {
	// 	path: "/system/message/unread",
	// 	name: "Unread",
	// 	meta: {
	// 		name: "未读信息",
	// 		icon: 'el-icon-notebook-2'
	// 	},
	// 	component: () => import('@/views/MessagePage/Unread/index.vue'),
	// },
	// {
	// 	path: "/system/message/read",
	// 	name: "Read",
	// 	meta: {
	// 		name: "已读信息",
	// 		icon: 'el-icon-circle-plus'
	// 	},
	// 	component: () => import('@/views/MessagePage/Read/index.vue')
	// }
	// 	]
	// },
	//404
	{
		path: "*",
		meta: {
			name: "404",
		},
		component: () => import('@/views/404/index.vue'),
		hidden: true
	},
]
export default routes;
/*
 * @Author: Murphy
 * @Date: 2021-05-31 19:56:24
 * @LastEditors: Murphy
 * @LastEditTime: 2021-06-07 15:51:51
 * @Description: file content
 */

import layout from '../views/Home/Home.vue'
export const ProcessingRoute = (route, id = 0) => {
	let allobj = []
	route.forEach((item, index) => {
		let obj = {
			path: item.path,
			name: item.name,
			meta: {
				name: item.meta_name,
				icon: item.icon
			},
			component: layout,
			children: []
		}

		item.menu_list.forEach((item1, index1) => {
			let obj2 = {
				path: item1.path,
				name: item1.name,
				meta: {
					name: item1.meta_name,
					icon: item1.icon
				},
				hidden:item1.is_button == 1 ? true : false,
				component: loadView(item1.component),
			}
			obj.children.push(obj2)
		})
		if (obj.children.length > 0) allobj.push(obj);
	})
	return allobj
}

// export const ProcessingRoute = (route, id = 0) => {
// 	let tree = [],temp;
// 	for (let parm of route) {
// 		if (parm.parent_id == id) {
// 			let obj
// 			if (parm.parent_id == 0) {
// 				obj = {
// 					path: parm.route_url,
// 					component: layout,
// 					name: parm.name,
// 					meta: {
// 						name: parm.name,
// 						icon: parm.icon
// 					}
// 				}
// 			} else {
// 				obj = {
// 					path: parm.route_url,
// 					component: loadView(parm.component),
// 					name: parm.name,
// 					meta: {
// 						name: parm.name,
// 						icon: parm.icon
// 					},
// 				}
// 			}
// 			temp = ProcessingRoute(route, parm.id);
// 			if (temp.length > 0) {
// 				obj.children = temp;
// 			}
// 			tree.push(obj)
// 		}
// 	}
// 	return tree
// }

// export const ProcessingRoute = (route) => {
//   console.log(route);
//   var ParenRoute = []
//   for (let param of route) {
//     if (param.pId == 0) {
//       let NewParam = {
//         path: param.path,
//         component: layout,
//         name: param.name,
//         meta: { name: param.metaName, icon: param.icon },
//       }

//       NewParam.children = createTree(route, param.id)

//       ParenRoute.push(NewParam)
//     }
//   }
//   return ParenRoute
// }

// /* 创建子菜单路由 */
// function createTree(arr, pId) {
//   let tree = []
//   arr.forEach((e) => {
//     if (pId == e.pId) {
//       /*  e.child = createTree(arr,e.id); */
//       let NewParam = {
//         path: e.path,
//         component: loadView(e.component),
//         name: e.name,
//         meta: { name: e.metaName, icon: e.icon },
//       }
//       tree.push(NewParam)
//     }
//   })
//   return tree
// }

const loadView = (view) => {
	/* 动态生成路由懒加载 */
	return () => import(`@/views/${view}`)
}
/*  (resolve) => require([`@/views/${view}`], resolve) */

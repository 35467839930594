//终端设备
import request from '@/network/request.js'
/**
 * 获取文件夹分组列表信息
 */
export function list_file_group (data){
	return request({
	  url: '/admin/device_resource/get_file_group',
	  method: 'post',
	  data,
	})
}


/**
 * 创建任务|文件夹|更新数据
 */
export function creat_file_interspace (data){
	return request({
	  url: '/admin/device_resource/creat_file_interspace',
	  method: 'post',
	  data,
	})
}
/**
 * 获取文件夹下面的文件
 */
export function get_group_resoce (data){
	return request({
	  url: '/admin/device_resource/get_group_resoce',
	  method: 'post',
	  data,
	})
}

/**
 * 获取分组中组的信息
 */
export function task_up_aller (data){
	return request({
	  url: '/admin/task/task_up_aller',
	  method: 'post',
	  data,
	})
}


/**
 * 上传文件
 */
export function file_interspace(data) {
	return request({
		url: '/admin/base/file_interspace',
		method: 'post',
		data,
	})
}


/**
 * 删除文件 | 删除目录
 */
export function delete_resoce(data) {
	return request({
		url: '/admin/device_resource/delete_resoce',
		method: 'post',
		data,
	})
}



/**
 * 删除文件 | 删除目录
 */
export function rename_file(data) {
	return request({
		url: '/admin/device_resource/rename_file',
		method: 'post',
		data,
	})
}

/**
 * 移动文件
 */
export function move_file(data) {
	return request({
		url: '/admin/device_resource/move_file',
		method: 'post',
		data,
	})
}

/**
 * 获取对应的ip地址信息
 */
export function get_locl_ip(data) {
	return request({
		url: '/admin/device_resource/get_locl_ip',
		method: 'post',
		data,
	})
}


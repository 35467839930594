import Vue from "vue";
import store from "../store";
import VueRouter from "vue-router";
import {
	message
} from "@/util/message";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css";
import routes from "./routes";
import {
	GetRoute
} from "./api";
import {
	ProcessingRoute
} from "@/util/getRoute.js";
Vue.use(VueRouter);

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});
const whiteList = ["/login"];
router.beforeEach(async (to, from, next) => {
	document.title = to.meta.name;
	const hasToken = localStorage.getItem("token");
	if (!hasToken) {
		// 如果没有登录那么执行白名单
		if (whiteList.indexOf(to.path) !== -1) {
			next();
		} else {
			next(`/login`);
			NProgress.done();
		}
	} else {
		if (!store.state.iphost) {
			store.dispatch("get_locl_ipfun", null);
		}
		// 如果用户登录过了，有token,url是登录页的话直接跳转到默认的根页面 /
		if (to.path == "/login") {
			next({
				path: "/"
			});
			store.dispatch("setTaskBarList", to);
			NProgress.done();
		} else {
			const user_name = localStorage.getItem("auton");
			if (user_name) {
				store.dispatch("setTaskBarList", to);
				next();
			} else {
				localStorage.setItem("auton", "2222");
				
				let newsss = [];
				// 动态路由的地方
				const res = await GetRoute(hasToken);
				let Newroute12 = ProcessingRoute(res.data);
				newsss = routes.concat(Newroute12);
				// let Newroute = []
				// newsss = routes.concat(Newroute);
				router.selfaddRoutes(newsss);
				/*
				  目前官方不推荐使用这个方法
				  router.options.routes = newsss;  
				  建议使用将动态（权限）路由的副本存放到vuex中
				*/
				store.commit('updatePowrRoutes', newsss)
				next(to.path);
			}
		}
	}
});

//改造动态路由
router.selfaddRoutes = function (params) {
	router.matcher = new VueRouter().matcher;

	/* 
  官方推荐的写法，但是报错addRoute is not a function，  应该是版本问题
 
  params.forEach(item => {
	router.addRoute(item)
  }) 
  */
	/*  addRoutes后续版本会被废弃 */

	console.log("动态路由是：", params)

	router.addRoutes(params);

};

router.afterEach(() => {
	NProgress.done();
});

export default router;

/**
 * 按钮是否有权限 有就返回true 无就false
 ***/
import store from "../store/index";
export function is_button_power(name) {
	let powrRouters = store.state.powrRouters;
	let status = false;
	powrRouters.forEach((item, index) => {
		if (item.children) {
			item.children.forEach((item1, index1) => {
				console.log("name是：",item1.name)
				if (item1.name == name && !status) {
					status = true;
				}
			})
		}
	})
	return status
}
